<template>
  <vs-card>
    <div
      class="mr-2"
      v-if="check_has_permission('exportPatientManagement')"
    >
      <vs-dropdown
        vs-custom-content vs-trigger-click
        
      >
        <vs-button :disabled="patients.length <= 0" icon-pack="feather" icon="icon-file-text" >
          Export
        </vs-button>
        <vs-dropdown-menu>
          <div class="flex flex-col gap-3 px-2 py-1">
            <ul>
              <li>
                <vs-radio v-model="exportMode" vs-value="current" :disabled="isButtonDisabled">
                  Export current table records
                </vs-radio>
              </li>
              <li>
                <vs-radio v-model="exportMode" vs-value="all" :disabled="isButtonDisabled">
                  Export all records
                </vs-radio>
              </li>
            </ul>
            <vs-button
              class="vs-con-loading__container w-full"
              id="button-with-loading"
              @click="exportCsvHandler"
              icon-pack="feather"
              icon="icon-download"
              
            >
              Export as .csv
            </vs-button>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>
      <a
        id="downloadCustomerCsv"
        ref="downloadCustomerCSVRef"
        :href="customerCSVurl"
      ></a>
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <div class="lg:hidden">
        <div class="mb-2 pt-2 pr-2 flex flex-no-wrap">
          <div class="w-full pr-1">
            <vs-input
              icon="search"
              style="width: 100%"
              placeholder="Search"
              v-model="searchInputString"
            />
          </div>
          <div class="w-24 pl-1">
            <vs-select
              style="width: 100%"
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="w-24 pl-1">
            <vs-select
              v-model="dataTableParams.clinicId"
              placeholder="All Clinic"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.name"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
        </div>
        <ul>
          <li :key="indextr" v-for="(tr, indextr) in patients">
            <div class="rounded mb-2 p-3" style="border: 1px solid #e8e8e8">
              <div class="flex pb-2">
                <h4 class="mr-2 mobile-text">
                  {{
                    patients[indextr].firstName
                      ? patients[indextr].firstName
                      : "N/A" | capitalize
                  }}
                </h4>
                <h4 class="mobile-text pb-2">
                  {{
                    patients[indextr].lastName
                      ? patients[indextr].lastName
                      : "N/A" | capitalize
                  }}
                </h4>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Gender: {{ patients[indextr].gender || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  DOB: {{ patients[indextr].dateOfBirth || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Contact Number: {{ patients[indextr].contactNumber || "N/A" }}
                </p>
              </div>
              <div>
                <p class="mobile-text pb-2">
                  Adress: {{ patients[indextr].address || "N/A" }}
                </p>
              </div>
              <div class="flex">
                <vs-button
                  type="border"
                  size="small"
                  @click="viewPatientDetail(patients[indextr]._id)"
                  icon-pack="feather"
                  class="mr-2"
                  >View</vs-button
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <vs-table
        class="hidden lg:inline"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="patients"
        @search="search"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-start"
        >
          <div>
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div>
            <vs-select
              v-model="dataTableParams.clinicId"
              placeholder="All Clinic"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.name"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">Full Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="gender">Gender</vs-th>
          <vs-th sort-key="dateOfBirth">DOB</vs-th>
          <vs-th sort-key="address">Address</vs-th>
          <vs-th sort-key="contactNumber">Contact Number</vs-th>
          <!-- <vs-th>Profile Image</vs-th> -->
          <vs-th sort-key="createdAt">Created At</vs-th>
          <!-- <vs-th sort-key="status">Status</vs-th> -->
          <vs-th size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">
              {{ data[indextr].fullName }}
            </vs-td>
            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].lastName">
              {{ data[indextr].lastName }}
            </vs-td> -->
            <vs-td :data="data[indextr].gender">{{
              data[indextr].gender | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].dateOfBirth">
              {{ data[indextr].dateOfBirth | date_formatter }}
            </vs-td>
            <vs-td :data="data[indextr].address">
              {{ data[indextr].address }}
            </vs-td>
            <vs-td :data="data[indextr].contactNumber">
              {{ data[indextr].contactNumber }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].profileImage">{{ data[indextr].profileImage }}</vs-td> -->
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <!-- <vs-td :data="data[indextr].status">{{ data[indextr].status }}</vs-td> -->
            <vs-td :data="data[indextr]._id">
              <vs-button
                type="border"
                size="small"
                @click="viewPatientDetail(data[indextr]._id)"
                icon-pack="feather"
                class="mr-2"
                >View</vs-button
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div
        class="
          m-2
          flex
          sm:justify-between
          justify-center
          items-center
          flex-wrap
        "
        style="height: 35px; padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <!-- <vs-pagination :total="totalPage" v-model="dataTableParams.page"></vs-pagination> -->
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  name: "PatientList",
  components: {
    "v-select": vSelect,
  },
  props: ["patients", "totalDocs", "page", "noDataText", "show"],
  data() {
    return {
      customerCSVurl: false,
      isMounted: false,
      currentPage: 1,
      searchInputString: null,
      isButtonDisabled: false,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        activeClinicId: "",
        id: null,
        clinicId: null,
      },
      exportMode: "all",
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
      clinics: [{ clinicId: "all", name: "All Clinics" }],
    };
  },
  methods: {
    ...mapActions("patient", ["exportCsv"]),
    ...mapActions("organization", [
      "fetchOrganizationClinics",
      "fetchOrganizationAllClinics",
    ]),
    ...mapActions("clinic", [
      "fetchClinicList",
    ]),
    search(searching) {
      this.searchInputString = searching;
    },
    async fetchList() {
      this.$vs.loading();
      await this.$emit("fetchList", this.dataTableParams);
      this.serverResponded = true;
      this.$vs.loading.close();
    },
    async fetchClinics() {
      this.$vs.loading.close();
      const { userRole } = this.$store.state.AppActiveUser;
      if(userRole == "superAdmin") {
        this.fetchAllClinics()
      }else {
        this.getOrganizationAllClinicsList()
      }
      this.$vs.loading.close();
    },  
    async fetchAllClinics() {
      const {data} = await this.fetchClinicList()
      this.clinics = [
        { clinicId: "all", name: "All Clinics" },
        ...data.data.filter((item) => item.name != null)
        .map(clinic => ({name: clinic.name, clinicId: clinic._id})),
      ];
    },
    getOrganizationAllClinicsList() {
      this.fetchOrganizationAllClinics(this.dataTableParams.id).then((res) => {
        this.$vs.loading.close();
        this.clinics = [
          { clinicId: null, name: "All Clinics" },
          ...res.data.data,
        ];
        this.noDataText = "No Clinics Available";
      });
    },
    viewPatientDetail(id) {
      this.$emit("viewPatientDetail", id);
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    exportCsvHandler() {
      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: `#button-with-loading`,
        scale: 0.45,
      });

      this.isButtonDisabled = true;
      const exportCsvOptions = {
        limit: this.dataTableParams.limit,
        page: this.dataTableParams.page,
        exportMode: this.exportMode,
        clinicId: this.dataTableParams.activeClinicId != "all" ? this.dataTableParams.activeClinicId : null
      }

      this.exportCsv(exportCsvOptions)
        .then(async (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "freshclinic-patients.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isButtonDisabled = false;
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          this.isButtonDisabled = false;
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          console.log(err);
        });
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
    "dataTableParams.clinicId": function (newVal, oldVal) {
      this.dataTableParams.activeClinicId = newVal
      this.fetchList();
    },
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },

  async created() {
    this.dataTableParams.id = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.dataTableParams.activeClinicId = (await this.$store.state
      .AppActiveClinicId)
      ? this.$store.state.AppActiveClinicId
      : "all";
    this.fetchClinics()
    this.fetchList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
.mobile-text {
  color: #164d3d;
}
.vs-dropdown-menu {
  z-index: 99999;
}
</style>